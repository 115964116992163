<template>
  <div id="app">
    <router-view />

    <CommonSlide/>
  </div>
</template>

<script>
import CommonSlide from "@/components/common/commonSlide.vue"
export default {
  components:{
    CommonSlide
  }
};
</script>
<style lang="scss">
// 在app中引入 css文件
@import "@/assets/css/common.css";
@import "fullpage.js/dist/fullpage.min.css";

.el-backtop {
  width: 2.5rem !important;
  height: 2.5rem !important;
  right: 3rem !important;
  bottom: 3.75rem !important;
  font-size: 1.25rem !important;
}

html,
body,
#app {
  width: 100%;
  height: 100%;
}

::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
* {
  touch-action: none; 
}
// html {
//   overflow: hidden !important;
// }
.el-backtop,
.el-calendar-table td.is-today {
  color: #000 !important;
}

#fp-nav ul li a.active span,
.fp-slidesNav ul li a.active span {
  background: #014099; /*这里设置的是活动导航的颜色*/
}
#fp-nav ul li a span,
.fp-slidesNav ul li a span {
  background: #014099; /*这里设置的是活动导航的颜色*/
}
</style>
