import Vue from 'vue'
import VueRouter from 'vue-router'
// import { mapState, mapMutations } from "vuex";
import store from "../store/index"

Vue.use(VueRouter)

const routes = [
  {
    path: '/homecontent',
    name: 'homecontent',
    component: () => import('../views/home/HomeViewRoute.vue'),
    children: [
      {
        path: '/home',
        name: 'home',
        component: () => import('../views/home/HomeView.vue'),
        meta: {
          isShow: true,
          title: "中控数科-关于我们",
          content: {
            keywords: "关于中控数科",
            description: "中控数科（陕西）信息科技有限公司成立于2019年，是一家专注于金融科技与信用科技的高新技术企业。公司以国家政策为导向，致力于公共资源交易和政府采购领域。"
          }
        }
      },
      {

        path: '/personIntroduction',
        name: 'personIntroduction',
        // hideInMenu:true,
        component: () => import('../views/homeHidden/homeHiddenView.vue'),
        

        meta: {
          isShow: true,
          title: "中控数科-个人简介",
        }

      }
    ]

  },
  {
    path: '/consultcontent',
    name: 'consultview1',
    component: () => import('../views/consult/ConsultView1.vue'),
    children: [
      {
        path: '/consult',
        name: 'consultview',
        component: () => import('../views/consult/ConsultView.vue'),
        meta: {
          isShow: true,
          title: "中控数科-动态资讯",
          content: {
            keywords: "动态资讯-中控数科",
            description: "中控数科动态资讯栏目，带您了解行业前沿资讯以及公司动态。"
          }
        }
      },
      {
        path: '/consultinfo',
        name: 'consultinfo',
        component: () => import('../views/consultInfo/ConsultInfo.vue'),
        meta: {
          isShow: true,
          content: {
            keywords: "动态资讯-新闻详情-中控数科",
          }
        }
      },
    ]
  },
  {
    path: '/union',
    name: 'union',
    component: () => import('../views/union/UnionView.vue'),
    meta: {
      isShow: true,
      title: "中控数科-合作联盟",
      content: {
        keywords: "合作联盟",
        description: "什么是合作联盟？创新公共资源交易平台建设、推动黄河流域公共资源交易跨区域合作，为深入贯彻党中央、国务院推进黄河流域生态保护与高质量发展国家战略，创新公共资源交易平台建设、推动黄河流域公共资源交易跨区域合作。"
      }
    }
  },
  {
    path: '/product',
    name: 'product',
    component: () => import('../views/product/ProductView.vue'),
    meta: {
      isShow: true,
      title: "中控数科-产品服务",
      content: {
        keywords: "电子保函平台,融资服务平台,场景信用评价报告,移动CA证书",
        description: "中控数科面向全国提供：电子保函平台,融资服务平台,场景信用评价报告,移动CA证书等服务。"
      }
    }
  },
  {
    path: '/case',
    name: 'case',
    component: () => import('../views/case/CaseView.vue'),
    meta: {
      isShow: true,
      title: "中控数科-客户案例",
      content: {
        keywords: "客户案例",
        description: "中控数科有着丰富的客户案例，包括：多元电子保函，手机移动APP，审批系统，信用服务等案例。"
      }
    }
  },
  {
    path: '/space',
    name: 'space',
    // hideInMenu:true,
    component: () => import('../views/space/SpaceView.vue'),

  },





  {
    path: '/',
    name: 'index',
    component: () => import('../views/index/IndexView.vue'),
    meta: {
      isShow: true,
      title: "中控数科-金融科技+信用科技服务商",
      content: {
        keywords: "中控数科,电子保函平台,智慧政务服务平台,场景信用评价报告,移动CA证书",
        description: "中控数科（陕西）信息科技有限公司成立于2019年，是一家专注于金融科技与信用科技的高新技术企业。公司以国家政策为导向，致力于公共资源交易和政府采购领域。",
        servicephone: "服务热线：400-029-1010"
      }

    }
  },
  {
    path: "/",
    redirect: "/home"
  },
  {
    path: '/:catchAll(.*)',
    name: "404",
    component: () => import('@/views/error/404'),
  },
]

const scrollBehavior = (to, from, savedPosition) => {
  //if判断可加可不加、根据自己需求
  //savedPosition当且仅当通过浏览器的前进/后退按钮触发时才可用
  if (savedPosition) {
    return savedPosition
  }
  return {
    x: 0,
    y: 0
  }
}


const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior
})

router.beforeEach((to, from, next) => {
  if (to.path === '/') {
    store.commit("EDITACTIVE1", 0);
    next();
  }

  if (to.path === '/product') {
    store.commit("EDITACTIVE1", 1);
    next();
  }
  if (to.path === '/union') {
    store.commit("EDITACTIVE1", 2);
    next();
  }
  if (to.path === '/case') {
    store.commit("EDITACTIVE1", 3);
    next();
  }
  if (to.path === '/consult') {
    store.commit("EDITACTIVE1", 4);
    next();
  }
  if (to.path === '/home') {
    store.commit("EDITACTIVE1", 5);
    next();
  }

  if (to.path === '/personIntroduction') {
    store.commit("EDITACTIVE1", 5);
    next();
  }

  else {
    next();
  }

  // 设置标题
  if (to.meta.title) {
    const head = document.getElementsByTagName('head')[0];
    // title
    const existingTitleMeta = document.querySelector("title");
    if (existingTitleMeta) {
      existingTitleMeta.remove(); // 移除现有的描述 meta 标签
    }

    // 设置新的title标题
    const titleMeta = document.createElement('title');
    titleMeta.innerHTML = to.meta.title || "中控数科";
    head.appendChild(titleMeta); // 添加新的标题 title标签
  } else {
    const head = document.getElementsByTagName('head')[0];
    const existingTitleMeta = document.querySelector("title");
    if (existingTitleMeta) {
      existingTitleMeta.remove(); // 移除现有的描述 meta 标签
    }
    // 设置新的title标题
    const titleMeta = document.createElement('title');
    titleMeta.innerHTML = "中控数科";
    head.appendChild(titleMeta); // 添加新的标题 title标签
  }
  if (to.meta.content) {
    const head = document.getElementsByTagName('head')[0];
    // 删除现有名称为 "keywords" 和 "description" 的 meta 标签
    const existingKeywordsMeta = document.querySelector('meta[name="keywords"]');
    if (existingKeywordsMeta) {
      existingKeywordsMeta.remove(); // 移除现有的关键词 meta 标签
    }
    const existingDescriptionMeta = document.querySelector('meta[name="description"]');
    if (existingDescriptionMeta) {
      existingDescriptionMeta.remove(); // 移除现有的描述 meta 标签
    }

    const existingServixeMeta = document.querySelector('meta[name="servicephone"]');
    if (existingServixeMeta) {
      existingServixeMeta.remove(); // 移除现有的描述 meta 标签
    }

    if (to.meta.content.servicephone) {
      const serviceMeta = document.createElement('meta');
      serviceMeta.setAttribute('name', 'servicephone');
      serviceMeta.setAttribute('content', to.meta.content.servicephone);
      head.appendChild(serviceMeta); // 添加新的描述 meta 标签

    }

    // 创建并设置新的关键词和描述 meta 标签
    const keywordsMeta = document.createElement('meta');
    keywordsMeta.setAttribute('name', 'keywords');
    keywordsMeta.setAttribute('content', to.meta.content.keywords);
    head.appendChild(keywordsMeta); // 添加新的关键词 meta 标签

    if (to.meta.content.description) {
      const descriptionMeta = document.createElement('meta');
      descriptionMeta.setAttribute('name', 'description');
      descriptionMeta.setAttribute('content', to.meta.content.description);
      head.appendChild(descriptionMeta); // 添加新的描述 meta 标签
    }




    // 可选：如果需要，更新名为 "content" 的其他 meta 标签
    const contentMeta = document.querySelector('meta[name="content"]');
    if (contentMeta) {
      contentMeta.setAttribute('content', to.meta.content); // 更新 content 属性
    }
  } else {
    // 删除现有名称为 "keywords" 和 "description" 的 meta 标签
    const existingKeywordsMeta = document.querySelector('meta[name="keywords"]');
    if (existingKeywordsMeta) {
      existingKeywordsMeta.remove(); // 移除现有的关键词 meta 标签
    }
    const existingDescriptionMeta = document.querySelector('meta[name="description"]');
    if (existingDescriptionMeta) {
      existingDescriptionMeta.remove(); // 移除现有的描述 meta 标签
    }

    //  if (!to.meta.content.servicephone) {
    const existingServixeMeta = document.querySelector('meta[name="servicephone"]');
    if (existingServixeMeta) {
      existingServixeMeta.remove(); // 移除现有的描述 meta 标签
    }
    //  }
  }
})



function getRoutesList(routes, pre) {
  return routes.reduce((array, route) => {
    const path = `${pre}${route.path}`;

    if (route.path !== '*') {
      array.push(path);
    }

    if (route.children) {
      array.push(...getRoutesList(route.children, `${path}/`));
    }

    return array;
  }, []);
}


// getRoutesList(router.options.routes, 'https://zigamiklic.com');
function getRoutesXML() {
  // const list = getRoutesList(router.options.routes, 'https://www.gjdbyy.com/')
  const list = getRoutesList(router.options.routes, 'https://www.zksk.com/')
    .map(route => `<url><loc>${route}</loc></url>`)
    .join('\r\n');
  return `<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:schemaLocation="http://www.sitemaps.org/schemas/sitemap/0.9 http://www.sitemaps.org/schemas/sitemap/0.9/sitemap.xsd">
    ${list}
  </urlset>`;
}

console.log(getRoutesXML())

export default router;
