import App from './App.vue'
import router from './router'
import Vue from "vue"
import VueRouter from 'vue-router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'fullpage.js/vendors/scrolloverflow';
import VueFullpage from 'vue-fullpage.js'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import VueLazyload from 'vue-lazyload'
import animate from 'animate.css'
import $ from 'jquery';
import './assets/js/pxrem'
// import 'default-passive-events'




Vue.use(VueLazyload, {
  preLoad: 1.3,
  // 懒加载默认加载图片
  loading: "https://img1.imgtp.com/2022/10/02/61x2dHvC.png",
  // 加载失败后加载的图片
  error: 'https://img1.imgtp.com/2022/10/02/61x2dHvC.png',
  attempt: 1
  // the default is ['scroll', 'wheel', 'mousewheel', 'resize', 'animationend', 'transitionend']
  // listenEvents: [ 'scroll' ]
})


// import "./rem"
// Vue.prototype.$ = $

// Vue.use(animate)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
// 如果你的改了push还是没有生效，可以考虑改replace方法 
// 修改路由replace方法,阻止重复点击报错
const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err);
};
Vue.use(VueRouter)
Vue.use(VueAwesomeSwiper)
Vue.use(ElementUI);
Vue.use(VueFullpage)



Vue.config.productionTip = false;





// 全屏滚动 vue-fullpage.js

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
