<template>
   <!-- 动画 -->
    <div
      :class="slideShow ? 'animate_header show1' : 'animate_header hidden'"
      @click="handleClose"
    >
      <div class="yuan" @click.stop="handleSlide">
        <ul class="text">
          <li>
            <div class="title" @click="$router.push('/product')">产品服务</div>
          </li>
          <li>
            <div class="title" @click="$router.push('/union')">合作联盟</div>
          </li>
          <li>
            <div class="title" @click="$router.push('/case')">客户案例</div>
          </li>
          <li>
            <div class="title" @click="$router.push('/consult')">动态资讯</div>
          </li>
          <li>
            <div class="title" @click="$router.push('/home')">关于我们</div>
          </li>
        </ul>

        <div class="cuo" @click.stop="handleClose">
          <i class="el-icon-close"></i>
        </div>
      </div>
    </div>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  data() {
    return {
    //   show: false,
    };
  },
  computed:{
    slideShow:{
        get(){
            return  this.$store.state.slideShow
        }
    }
  },
  methods: {
     ...mapMutations(["EditSlideShow"]),
    handleClose() {
        this.EditSlideShow(false);
    },
    handleSlide(){
       this.EditSlideShow(true);
    },
  },
};
</script>

<style lang="scss" scoped>
.animate_header {
  width: 128rem;
  height: 100rem;
  // position: absolute;
  position: fixed;
  top: 0;
  right: 0;
  // background-color: yellow;
  z-index: 99;
  overflow: hidden;

  .yuan {
    position: absolute;
    top: -32%;
    left: 70%;
    width: 60rem;
    height: 60rem;
    border-radius: 50%;
    background: #87a4e9f1;
    // opacity: 0.8;
    border: 6.25rem solid #b8c8f3;
    animation: show 2s;

    .text {
      position: absolute;
      bottom: 12%;
      left: 24%;
      width: 10rem;

      > li {
        font-size: 1.6rem;
        padding: 1.25rem;
        text-align: center;
        color: white;
        cursor: pointer;
      }
    }

    .cuo {
      position: absolute;
      bottom: 31rem;
      left: 29rem;
      cursor: pointer;
      i {
        font-size: 1.875rem;
        color: #fff;
      }
    }

    // animation: show ;
    // display: none;
  }
}

@keyframes show {
  0% {
    opacity: 0;
    transform: translate(10%, -10%);
    // transform: translateY(-6%);
  }

  60% {
    opacity: 0.6;
  }

  100% {
    opacity: 1;
    transform: translateX(0%, 0%);
    // transform: translateY(0%);
  }
}

.show1 {
  display: block;
  transition: 2s;
}

</style>