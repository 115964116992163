import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    active1: 0,
    slideShow:false,
  },
  // getters: {
  // },
  mutations: {
    EDITACTIVE1(state, payload) {
      state.active1 = payload;
    },
    EditSlideShow(state, payload){
      state.slideShow = payload;
    },
  },
  actions: {
  },
  modules: {
  },
  plugins: [createPersistedState(
    {
      storage: window.sessionStorage,  // 同localStorage相同，只是将vuex的所有值存储到sessionStorage中
      reducer(val) {
        // 此时，当count发生改变的时候，就会调用此函数，并且val的值为当前state对象，return的值为当前本地存储的value值（本地存储的key值为vuex）
        return {
          active1: val.active1,
          //  changeCount: 'aaa'
        }
      }
    },

  )]
})
